import React, { useEffect } from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import $ from "jquery";
import locales from "../../../constants";

const Rspwn = ({ pageContext: { slug } }) => {
  useEffect(() => {
    $(".glitchHoverEffect").hover(function () {
      $(".glitch").toggleClass("effectOn");
    });
  });
  const lang = "en";
  const nav = {
    initialSlideIndex: 1,
  };
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #c82137, #ea253f, #ff3a5f, #ea253f, #c82137 )",
        icons: "#C82137",
        navClass: "rspwn",
      }}
      seo={{
        title: "RSPWN",
        headerTitle: "rspwn",
        href: slug,
        lang: "en",
        ogImage: require("../../../assets/img/portfolio/rspwn_top_back.png"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/rspwn/",
      }}
    >
      <PortfolioHeader name="rspwn" height="75" />
      <section className="container-fluid rspwn_section_2" id="info">
        <div className="row">
          <div className="col-lg-6">
            <div className="inner">
              <h1>RSPWN</h1>
              <ul>
                <li>Logo</li>
                <li>Online store</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="inner">
              <p>
                RSPWN is a new gamewear brand dedicated to all hardcore players.
                It doesn't matter if your anthem is “EA Sport it’s in the game”
                or if you prefer to traverse the Summoner's Rift by setting up
                invisible, poisonous mushrooms everywhere, or playing AP LUX on
                a support. Even if you are a "Rush B!" strategist then any of
                your respawns on rspwn.gg is more than welcome!
              </p>
              <p>
                We made the logo and a functional model of an online store (UX /
                UI) ,on the basis of which we designed and implemented a sales
                platform for a brand which has of the most recognizable eSports
                teams in Poland - IZAKO BOARS as their front man.
              </p>
              <h3>Logo</h3>
              <p>
                <strong>
                  R<span>E</span>SP<span>A</span>WN
                </strong>{" "}
                is being reborn - if you lose your life in the game, you have a
                chance to start again, usually from the very beginning. In the
                logo we designed, arrows pointing in opposite directions and
                shown in the negative space of the logotype symbolize this.
              </p>
              <p>
                A simple, bolded, sans serif font, emphasizes the
                professionalism and reliability of the brand, and the slight
                slant of the letters highlights its dynamism. After all, the
                world of gaming and its fans is a demanding industry in which
                continuous development is the very basis.
              </p>
              <p>
                Only so much and as much.There is no room for quirks. RSPWN is a
                specific brand for those who always fight till the end.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid rspwn_section_3">
        <div className="row">
          <div className="col-lg-6 col_1">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/rspwn_logo_colors.png")}
              alt=""
            />
            <div className="img_wrapper">
              <img
                className="img-fluid"
                src={require("../../../assets/img/portfolio/rspwn_logo.svg")}
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-4 offset-lg-2 col_2">
            <div className="row">
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>Black CMYK</p>
                    <h5>
                      <span>0</span>
                      <span>0</span>
                      <span>0</span>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 100 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>Gold CMYK</p>
                    <h5>
                      <CountUp start={0} duration={3} end={isVisible ? 0 : 0} />
                      <CountUp start={0} duration={3} end={isVisible ? 0 : 0} />
                      <CountUp start={0} duration={3} end={isVisible ? 0 : 0} />
                      <CountUp start={0} duration={3} end={isVisible ? 0 : 0} />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <div className="col-sm-6">
                <p>RGB</p>
                <h5>
                  <span>0</span>
                  <span>0</span>
                  <span>0</span>
                </h5>
              </div>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>RGB</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 255 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 255 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 255 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>000000</h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>ffffff</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="rspwn_section_4">
        <div className="row no-gutters">
          <div className="col-lg-5">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/rspwn_main_1.png")}
              alt=""
            />
          </div>
          <div className="col-lg-6 offset-lg-1 col_2">
            <div className="inner">
              <h3>Layout</h3>
              <p>
                Wherever the goal is to convert a visitor to sales, we strongly
                focus on the user's experience affecting his purchasing
                decisions. These are often emotional, so in order to maximize
                the chance of conversion, the prototype was designed in such a
                way that the website effectively accomplishes the goals
                discussed with the Client during project workshops.
              </p>
              <p>
                The layout was created based on the needs of future users and
                the best solutions currently used in e-commerce industry. We
                built a sense of belonging and community thanks to numerous
                references to the team.
              </p>
            </div>
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../../assets/img/portfolio/rspwn_entire_page.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="rspwn_section_5">
        <div className="row no-gutters">
          <div className="col_1">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/rspwn_main_3.png")}
              alt=""
            />
          </div>
          <div className="col-lg-5 offset-lg-4 col-md-7 col_2">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/rspwn_main_4.png")}
              alt=""
            />
          </div>
          <div className="col-lg-3 col-md-5 col_3">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/rspwn_main_5.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="rspwn_section_6">
        <div className="row no-gutters">
          <div className="col-xl-3 col-md-4">
            <div className="glitch glitch--style-1">
              <div className="glitch__img"></div>
              <div className="glitch__img"></div>
              <div className="glitch__img"></div>
              <div className="glitch__img"></div>
              <div className="glitch__img"></div>
              <img
                className="img-fluid"
                src={require("../../../assets/img/portfolio/rspwn_main_6.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-6 offset-xl-2 col-md-6 offset-md-1">
            <div className="inner">
              <h3>eStore</h3>
              <p>
                <strong className="glitchHoverEffect">
                  The CORE collection
                </strong>{" "}
                signed with the RSPWN sign and{" "}
                <strong className="glitchHoverEffect">
                  the IZAKO BOARS collection
                </strong>{" "}
                had amazing design, so we had to prepare the right background
                for them. The presentation of products is extremely important,
                therefore. we gave up on any unnecessary ornaments and other
                distractions by putting on a simple design oscillating around
                the theme (e.g. the pixel icon 'add to favorites'). Again, we
                focused on simplicity and dynamism, after all, the site must be
                tailored to your audience. Products were supposed to reign in
                here.
              </p>
            </div>
          </div>
        </div>
        <div className="img_wrapper">
          <video
            width="100%"
            height="260px"
            loop
            autoPlay
            muted
            data-setup="{}"
          >
            <source
              src={require("../../../assets/video_portfolio/rspwn_main_7.webm")}
              type="video/webm"
            />
            <source
              src={require("../../../assets/video_portfolio/rspwn_main_7.mp4")}
              type="video/mp4"
            />
          </video>
        </div>
      </section>

      <section className="rspwn_section_7">
        <div className="row no-gutters">
          <div className="col-lg-5 offset-lg-1 col-md-6">
            <div className="inner">
              <p>
                As a result of the analysis of needs, brand development plans in
                terms of product offer and the budget, we decided together with
                the Client that at this stage the best solution would be to base
                the implementation of the website on a flexible WooCommerce
                engine that meets the requirements of all the assumed project
                needs. We also took care of the comfortable use of the store on
                mobile devices so that the store would be available to all of
                its customers on any device.
              </p>
              <h3>GL&HF!</h3>
            </div>
          </div>
          <div className="col-lg-4 offset-lg-2 col-md-6 text-right">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/rspwn_main_8.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="rspwn_section_8">
        <div className="row no-gutters">
          <div className="col-md-5">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/rspwn_main_9.png")}
              alt=""
            />
          </div>
          <div className="col-md-3">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/rspwn_main_10.png")}
              alt=""
            />
          </div>
          <div className="col-md-3 offset-md-1 col_3">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/rspwn_main_11.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};

export default Rspwn;
